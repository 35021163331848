import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';



import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        setCountry(data.country);
        setRegion(regionNames.of(data.country.toUpperCase()));
        setCity(data.city);
      })
      .catch(error => console.error('Fetch error:', error));
  }, []);

  const imageUrls = [
    'https://i.ibb.co/W5QwWY2/cropped-photo-2024-06-18-02-33-30.jpg',
    'https://i.ibb.co/Jkpy9jY/cropped-photo-2024-06-18-02-33-39.jpg',
    'https://i.ibb.co/QrHf1kJ/cropped-photo-2024-06-18-02-33-41.jpg',
    'https://i.ibb.co/LPpZqG9/cropped-photo-2024-06-18-02-33-45.jpg',
    'https://i.ibb.co/xGLG18F/cropped-photo-2024-06-18-02-33-46.jpg',
    'https://i.ibb.co/YPVQymw/cropped-photo-2024-06-18-02-33-48.jpg',
    'https://i.ibb.co/QbKknLF/cropped-photo-2024-06-18-02-33-49.jpg',
    'https://i.ibb.co/cy1z5q3/cropped-photo-2024-06-18-02-33-50.jpg',
    'https://i.ibb.co/8xvjcrk/cropped-photo-2024-06-18-02-33-51.jpg',
    'https://i.ibb.co/syP77f0/cropped-photo-2024-06-18-02-33-53.jpg',
    'https://i.ibb.co/yFDzpVC/cropped-photo-2024-06-18-02-33-54.jpg',
    'https://i.ibb.co/DtMpLWG/cropped-photo-2024-06-18-02-33-56.jpg'
  ];

  return (
    <div className="container">
    <img 
      src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
      alt="Header Image"
      className="header-image"
    />
    <Swiper
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      slidesPerView={'2'}
      coverflowEffect={{
      rotate: 15,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
     }}
    autoplay={{ // Corrected from autoPlay to autoplay
      delay: 2000,
      disableOnInteraction: false,
    }}
    breakpoints={{
      // Screens smaller than 640px
      640: {
        slidesPerView: 2,
      },
      // Larger screens
      640: {
        slidesPerView: 3, // or whatever number you prefer
      },
    }}
    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
    className="swiper-container"
  >
    {imageUrls.map(url => (
      <SwiperSlide key={url} className="swiper-slide">
        <img src={url} alt="slide_image"/>
      </SwiperSlide>
    ))}
      <div className="slider-controler">
        <div className="swiper-pagination"></div>
      </div>
    </Swiper>

    <div className='textContainer'>
      <img src='https://i.ibb.co/D8zvNRM/cropped-photo-2024-06-18-02-33-53-modified.png'/>
      <section>
          <div className='flexRow'>Ciara 💕 <div className='buttonStyling'>Online Now</div></div>
          <div className='flexRow'>
            <span>
            Your 19yo computer science student currently in {city}, {region}   {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
              />} 
          
              
            </span>
          </div>


      </section>
    </div>

    <a href="https://onlyfans.com/ciarastarr/c1" id="customButton">
      Send me a message
    </a>

    <p className="exclusiveContent">
      Exclusive content 💖 75% Off for a limited time only! $3 for the next person ONLY<br/><br/>
      Looking for content partners, DM me on OnlyFans to film w/ me 🎥💓
    </p>
    <Analytics/>
  </div>
  );
}

export default App;
